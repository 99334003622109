import React from "react";
import Psi from "@images/psi.webp";
import Iacp from "@images/iacp.webp";
import Nuig from "@images/nuig.webp";
import Profile from "@images/profile.webp";
import WordCloud from "@images/wordcloud.webp";
import * as styles from "./about.module.scss";

const AboutSeoige = () => {
  return (
    <div className={styles.sectionLayout}>
      <div className={styles.sectionText}>
        <h2 className={styles.title}>Seoige Therapy</h2>
        <p className={styles.paragraph}>
          Seoige Therapy offers Counselling and Psychotherapy to clients on a
          wide range of psychological and emotional issues. Counselling is
          generally a shorter term therapy for identifiable participating
          issues, whereas Psychotherapy generally involves longer therapy to
          identify and resolve deeper core inherent issues. Seoige Therapy
          provides face to face and on-line counselling and psychotherapy.
          Based in Galway City with easy access to parking.
          <span className={styles.lineBreak}></span>
          Counselling and Psychotherapy can be for anyone seeking support during
          difficult or challenging periods in their life. The challenges maybe
          due to a recent experience or maybe due to an experience from the past
          which is impacting present functioning in everyday life. Or there may
          be no apparent reason for feeling lost in one’s own life’s journey,
          alone and unable to cope. Whatever brings you to therapy Seoige
          Therapy provides a safe, non-judgemental space and meets you where you
          are currently at. It is within this space the individual feels safe to
          explore, understand and change negative/limiting thoughts, beliefs,
          feelings and behaviours and become more self aware and develops
          resilience and self-belief. True happiness is an inside job!!
        </p>
      </div>
      <div className={styles.sectionImages}>
        <img
          className={styles.wordcloud}
          src={WordCloud}
          alt="Word Cloud"
        ></img>
      </div>
    </div>
  );
};

const AboutMe = () => {
  return (
    <div className={`${styles.sectionLayout} ${styles.aboutMe}`}>
      <div className={styles.sectionImages}>
        <img src={Profile} className={styles.profileImg} alt="Profile"></img>
      </div>
      <div className={styles.sectionText}>
        <h2 className={styles.title}>
          Counselling &amp; Psychotherapy Training and Accreditation
        </h2>
        <p className={styles.paragraph}>
          Briedge McCarneyJoyce completed a Bachelor of Arts (Hons) in
          Psychological Studies and Sociology, followed by a Higher Diploma in
          Psychology from NUIG. Briedge gained her clinical training while
          completing a BA in Holistic Counselling and Psychotherapy at the
          International College of Personal &amp; Professional Development
          (ICPPD), Athlone. Briedge has completed Professional Diploma, Trauma
          Informed Psychotherapy, The Greenane Centre and holds a Certificate in Couples Therapy awarded by Institute of Couples Therapy. Within her clinical
          training Briedge studied a variety of therapeutic approaches and
          theoretical inputs such as Gestalt, Person Centred Approach, Holistic
          Approach, Family and Systemic Processes, Psychosynthesis
          Psychotherapy, Loss and Bereavement, Psychodynamic Perspectives,
          Abnormal Psychology &amp; Mental Health Issues and CBT. Briedge is
          committed to continuous personal and professional development and
          attends relevant training and workshops and regular supervision.
          <span className={styles.lineBreak}></span>
          Briedge is a graduate member of Psychological Society of Ireland (PSI)
          and accredited member of Irish Association for Counsellors and
          Psychotherapists (IACP). IACP is the primary regulating body and was
          established in 1981 to identify, develop and maintain professional
          standards of excellence in Counselling and Psychotherapy in Ireland.
          Briedge is QQI qualified and Garda Vetted.
        </p>
      </div>
    </div>
  );
};

const EducationAndExperiece = () => {
  return (
    <div className={styles.sectionLayout}>
      <div className={styles.sectionText}>
        <h2 className={styles.title}>
          Counselling Experience and Areas of Interest
        </h2>
        <p className={styles.paragraph}>
          Briedge has a broad range and diverse working experience within the
          mental health field for over 20 years. She has worked as a Holistic
          Counsellor and Psychotherapist for a number of years in General
          Counselling supporting adults and students in various Counselling
          Charity Organizations, NUIG Counselling &amp; Wellbeing Service, along
          with her own private practice.
          <span className={styles.lineBreak}></span>
          Briedge’s holistic practice utilizes an integrated approach that
          provides a compassionate, non- judgmental space that is unique and
          suited to each individual’s presenting need depending on the
          issues/challenges a client presents with. Carl Rodgers, the American
          Psychologist states,
          <i className={styles.quote}>
            “We think we listen, but very rarely do we listen with real
            understanding, true empathy. Yet, listening, of this special kind,
            is one of the most potent forces for change that I know.”
          </i>{" "}
          It is from this space a therapeutic alliance develops on trust and
          safety and empowers the client to look inward for the answers they
          seek as they are the true experts on themselves.{" "}
          <i className={styles.quote}>
            {" "}
            “Your vision becomes clear only when you look into your heart, who
            looks outside; dreams, who looks inside; awakens”...
          </i>{" "}
          Carl Jung.
          <span className={styles.lineBreak}></span>
          Briedge’s special area of interest is working with individuals
          experiencing difficulties in addiction and trauma and its impact on
          the family system
        </p>
      </div>
      <div className={styles.sectionImages}>
        <img className={styles.logo} src={Nuig} alt="NUIG Logo"></img>
        <img className={styles.logo} src={Psi} alt="PSI Logo"></img>
        <img className={styles.logo} src={Iacp} alt="IACP Logo"></img>
      </div>
    </div>
  );
};

const About = () => {
  return (
    <section className={styles.about} id="about">
      <div id="about-anchor" className={styles.anchor}></div>
      <AboutSeoige />
      <AboutMe />
      <EducationAndExperiece />
    </section>
  );
};

export default About;
